$grid-debug: false;

@import "./styles/media.scss";
@import "./styles/grid.scss";

* {
	margin: 0;
	padding: 0;
}

body {
	font-family: source-han-serif-tc, serif;
}

.container,
.row,
.column {
	box-sizing: border-box;
}

.no-gutters {
	&.container,
	&.container .column,
	&.row .column,
	&.column {
		padding-left: 0;
		padding-right: 0;
	}

	&.container .row,
	&.row {
		margin-left: 0;
		margin-right: 0;
	}
}

.container {
	@include make-container;
	@include make-debug-outline($grid-debug-container-color);
}

.row {
	@include make-row;
	@include make-debug-outline($grid-debug-row-color);
}

.column {
	@include make-column;
	@include make-debug-outline($grid-debug-column-color);
}
