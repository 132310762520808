﻿@import "config.scss";

@mixin make-debug-outline($color) {
	@if $grid-debug {
		outline: 1px dashed $color;
	}
}

@mixin make-container {
	$gutter: $grid-default-gutter / 2;
	margin-left: auto;
	margin-right: auto;
	padding-left: $gutter;
	padding-right: $gutter;
	width: 100%;

	@each $breakpoint, $width in $grid-breakpoints {
		@include media-over($breakpoint) {
			@if $breakpoint != xs {
				$gutter: map-get($grid-gutters, $breakpoint) / 2;
				padding-left: $gutter;
				padding-right: $gutter;
			}

			&:not(.fluid) {
				max-width: $width;
			}
		}
	}
}

@mixin make-row($column-selector: ".column") {
	$default-gutter: $grid-default-gutter / 2;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$default-gutter;
	margin-right: -$default-gutter;

	&.reverse {
		flex-direction: row-reverse;
	}

	&.space-around {
		justify-content: space-around;
	}

	&.space-between {
		justify-content: space-between;
	}

	@each $breakpoint in map-keys($grid-breakpoints) {
		@include media-under($breakpoint) {
			&.break-#{$breakpoint} > #{$column-selector} {
				flex: none;
				left: 0;
				right: 0;
				margin-left: 0;
				width: 100%;
				max-width: 100%;
			}
		}

		@include media-over($breakpoint) {
			@if $breakpoint != xs {
				$gutter: map-get($grid-gutters, $breakpoint) / 2;
				margin-left: -$gutter;
				margin-right: -$gutter;
			}

			&.break-#{$breakpoint} {
				left: auto;
				right: auto;
				width: auto;
			}
		}
	}
}

@mixin make-column {
	$gutter: $grid-default-gutter / 2;
	position: relative;
	flex-basis: 0;
	flex-grow: 1;
	padding-left: $gutter;
	padding-right: $gutter;
	width: 100%;
	max-width: 100%;

	&.width-auto {
		flex: 0 0 auto;
		width: auto;
	}

	&.pull-auto {
		right: auto;
	}

	&.push-auto {
		left: auto;
	}

	@for $i from 1 through $grid-columns {
		$width: percentage($i / $grid-columns);

		&.width-#{$i} {
			flex: 0 0 $width;
			max-width: $width;
		}

		&.pull-#{$i} {
			right: $width;
		}

		&.push-#{$i} {
			left: $width;
		}
	}

	@for $i from 1 through ($grid-columns - 1) {
		&.offset-#{$i} {
			margin-left: percentage($i / $grid-columns);
		}
	}

	@each $breakpoint in map-keys($grid-breakpoints) {
		@include media-over($breakpoint) {
			@if $breakpoint != xs {
				$gutter: map-get($grid-gutters, $breakpoint) / 2;
				padding-left: $gutter;
				padding-right: $gutter;
			}
		}
	}
}
