﻿@import "config.scss";

@mixin media-over($breakpoint) {
	@media (min-width: map-get($grid-breakpoints, $breakpoint)) {
		@content;
	}
}

@mixin media-over-xs {
	@include media-over(xs) {
		@content;
	}
}

@mixin media-over-sm {
	@include media-over(sm) {
		@content;
	}
}

@mixin media-over-md {
	@include media-over(md) {
		@content;
	}
}

@mixin media-over-lg {
	@include media-over(lg) {
		@content;
	}
}

@mixin media-over-xl {
	@include media-over(xl) {
		@content;
	}
}

@mixin media-under($breakpoint) {
	@media (max-width: map-get($grid-breakpoints, $breakpoint) - 1px) {
		@content;
	}
}

@mixin media-under-xs {
	@include media-under(xs) {
		@content;
	}
}

@mixin media-under-sm {
	@include media-under(sm) {
		@content;
	}
}

@mixin media-under-md {
	@include media-under(md) {
		@content;
	}
}

@mixin media-under-lg {
	@include media-under(lg) {
		@content;
	}
}

@mixin media-under-xl {
	@include media-under(xl) {
		@content;
	}
}
